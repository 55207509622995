import axios from "axios";
import { userURL } from "../components/Config/Url";

export const userService = {
  getUsers,
  getUserDetails,
  updateUser,
};

async function getUsers(companyId) {
  return axios
    .get(`${userURL}/${companyId}/users`)
    .then((res) => res)
    .catch((err) => err);
}

async function getUserDetails(companyId, userId) {
  return axios
    .get(`${userURL}/${companyId}/users/${userId}`)
    .then((res) => res)
    .catch((err) => err);
}

async function updateUser({
  externalId,
  companyId,
  firstName,
  lastName,
  department,
  email,
  fixNumber,
  fixNumberShort,
  mobNumber,
  mobNumberShort,
  role,
  status,
  username,
  fullName,
  id,
}) {
  return axios
    .put(`${userURL}/${companyId}/users/${id}`, {
      externalId,
      companyId,
      firstName,
      lastName,
      department,
      email,
      fixNumber,
      fixNumberShort,
      mobNumber,
      mobNumberShort,
      role,
      status,
      username,
      fullName,
      id,
    })
    .then((res) => res)
    .catch((err) => err);
}
