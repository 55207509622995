import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useNavigate, useLocation } from "react-router-dom";
import "../../../../src/global.css";

import {
  Alert as MuiAlert,
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  TextField,
  LinearProgress,
  Button,
} from "@mui/material";
import { spacing } from "@mui/system";
import { userService } from "../../../Services/userService";
import "./user.css";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function EmptyCard() {
  const { state } = useLocation();
  const navigate = useNavigate();

  let userId = state.userId;
  let companyId = state.companyId;

  const [userDetails, setUserDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    userService
      .getUserDetails(companyId, userId)
      .then((res) => {
        setUserDetails(res.data);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <Card mb={6}>
          <CardContent>
            {userDetails && (
              <Grid style={{ fontSize: "14px" }} container spacing={6}>
                <Grid item md={6}>
                  <div className="detailDiv">
                    <b>Ime:</b> {userDetails.firstName}
                  </div>
                  <div className="detailDiv">
                    <b>Priimek:</b> {userDetails.lastName}
                  </div>
                  <div className="detailDiv">
                    <b>Uporabniško ime:</b> {userDetails.username}
                  </div>
                  <div className="detailDiv">
                    <b>Email:</b> {userDetails.email}
                  </div>
                  <div className="detailDiv">
                    <b>Vloga:</b> {userDetails.role}
                  </div>
                  <div className="detailDiv">
                    <b>Oddelek:</b> {userDetails.department}
                  </div>
                </Grid>
                <Grid item md={6}>
                  <div className="detailDiv">
                    <b>Stanje:</b> {userDetails.status}
                  </div>
                  <div className="detailDiv">
                    <b>Telefonska številka:</b> {userDetails.fixNumber}
                  </div>
                  <div className="detailDiv">
                    <b>Kratka telefonska številka:</b>{" "}
                    {userDetails.fixNumberShort}
                  </div>
                  <div className="detailDiv">
                    <b>Mobilna številka:</b> {userDetails.mobNumber}
                  </div>
                  <div className="detailDiv">
                    <b>Kratka mobilna številka:</b> {userDetails.mobNumberShort}
                  </div>
                </Grid>
                <Grid item md={12}>
                  <Button
                    className="btnA1"
                    onClick={() => navigate(-1)}
                    type="button"
                    variant="contained"
                    mt={3}
                  >
                    V redu
                  </Button>
                </Grid>
              </Grid>
            )}
          </CardContent>
        </Card>
      )}
    </>
  );
}

function UserDetail() {
  return (
    <React.Fragment>
      <Helmet title="User" />
      <Typography variant="h3" gutterBottom display="inline">
        Uporabnik podrobnosti
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/dashboard">
          Domov
        </Link>
        <Link component={NavLink} to="/users">
          Uporabniki
        </Link>
        <Typography>Uporabnik podrobnosti</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EmptyCard />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default UserDetail;
