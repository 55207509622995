import React, { useState, useReducer } from "react";

let token = localStorage.getItem("userTokenSlo")
  ? localStorage.getItem("userTokenSlo")
  : "";

export const initialState = {
  token: "" || token,
  loading: false,
  errorMessage: "",
  type: "",
  username: "",
  firstName: "",
  lastName: "",
  role: "",
  userId: 0,
  companyId: 0,
};

export const AuthReducer = (initialState, action) => {
  switch (action.type) {
    case "REQUEST_LOGIN":
      return {
        ...initialState,
        loading: true,
      };
    case "LOGIN_SUCCESS":
      return {
        ...initialState,
        token: action.payload.token,
        loading: false,
        errorMessage: "",
        type: action.payload.type,
        username: action.payload.username,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        role: action.payload.role,
        userId: action.payload.userId,
        companyId: action.payload.companyId,
      };
    case "LOGOUT":
      return {
        ...initialState,
        token: "",
        loading: false,
        errorMessage: "",
        type: "",
        username: "",
        firstName: "",
        lastName: "",
        role: "",
        userId: 0,
        companyId: 0,
      };

    case "LOGIN_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
