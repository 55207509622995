import { rootURL } from "../Config/Url";

const ROOT_URL = rootURL;

export async function loginUser(dispatch, loginPayload) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(loginPayload),
  };

  try {
    dispatch({ type: "REQUEST_LOGIN" });
    let response = await fetch(`${ROOT_URL}`, requestOptions);
    let data = await response.json();

    if (data) {
      dispatch({ type: "LOGIN_SUCCESS", payload: data });
      localStorage.setItem("userTokenSlo", data.token);
      localStorage.setItem("currentUserSlo", JSON.stringify(data));
      return data;
    }

    dispatch({ type: "LOGIN_ERROR", error: data.username });
    console.log(data.username);
    return;
  } catch (error) {
    dispatch({ type: "LOGIN_ERROR", error: error });
    console.log(error);
  }
}

export async function logout(dispatch) {
  dispatch({ type: "LOGOUT" });
  localStorage.removeItem("currentUserSloUserId");
  localStorage.removeItem("currentUserSloCompanyId");
  localStorage.removeItem("currentUserSlo");
  localStorage.removeItem("tokenSlo");
  localStorage.removeItem("userTokenSlo");
  localStorage.removeItem("roleOfUserSlo");
}
