import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "../../../../src/global.css";

import {
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  TextField,
  LinearProgress,
  Button,
} from "@mui/material";
import { spacing } from "@mui/system";
import { CheckCircleOutline, ErrorOutline } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { userService } from "../../../Services/userService";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const CssTextField = styled(TextField, {
  shouldForwardProp: (props) => props !== "focusColor",
})((p) => ({
  // input label when focused
  "& label.Mui-focused": {
    color: p.focusColor,
  },
  // focused color for input with variant='standard'
  "& .MuiInput-underline:after": {
    borderBottomColor: p.focusColor,
  },
  // focused color for input with variant='filled'
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: p.focusColor,
  },
  // focused color for input with variant='outlined'
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: p.focusColor,
    },
  },
}));

function EmptyCard() {
  let roleOfUser = localStorage.getItem("roleOfUserSlo");

  let formSchema;
  if (roleOfUser == "ROLE_A1ADMIN") {
    formSchema = Yup.object().shape({
      fixNumber: Yup.string().required("Vpišite Telefonska številka!"),
    });
  } else {
    formSchema = Yup.object().shape({
      firstName: Yup.string().required("Vpišite Ime!"),
      lastName: Yup.string().required("Vpišite Priimek!"),
      email: Yup.string().email("Vpišite veljaven e-poštni naslov!"),
      mobNumber: Yup.string(),
      mobNumberShort: Yup.string(),
    });
  }

  const validationOpt = { resolver: yupResolver(formSchema) };

  const { register, handleSubmit, reset, formState } = useForm(validationOpt);

  const { errors } = formState;
  const { state } = useLocation();
  const navigate = useNavigate();

  let userId = state.userId;
  let companyId = state.companyId;

  const [isLoading, setIsLoading] = useState(true);
  const [userDetails, setUserDetails] = useState({});
  const [isSuccessfull, setIsSuccessfull] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    userService
      .getUserDetails(companyId, userId)
      .then((res) => {
        setUserDetails(res.data);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const submit = (data) => {
    setIsLoading(false);

    userService
      .updateUser({
        externalId: userDetails.externalId,
        companyId: companyId,
        firstName: data.firstName,
        lastName: data.lastName,
        department: data.department,
        email: data.email,
        fixNumber: data.fixNumber,
        fixNumberShort: data.fixNumberShort,
        mobNumber: data.mobNumber,
        mobNumberShort: data.mobNumberShort,
        role: userDetails.role,
        status: userDetails.status,
        username: userDetails.username,
        fullName: userDetails.fullName,
        id: userId,
      })
      .then((res) => {
        if (res.status == 200) {
          setIsSuccessfull(true);
        } else {
          setIsSuccessfull(false);
          setErrorMessage(res.statusText);
        }
        setIsLoading(false);
        setOpenDialog(true);
      })
      .catch((err) => {
        setIsSuccessfull(false);
        setErrorMessage("Dogodila se greška prilikom uređivanja korisnika.");
        setOpenDialog(true);
        setIsLoading(false);
      });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    navigate("/users");
  };

  return (
    <>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <Card mb={6}>
          <CardContent>
            <form style={{ marginTop: "20px" }} onSubmit={handleSubmit(submit)}>
              {roleOfUser == "ROLE_A1ADMIN" ? (
                <Grid container spacing={8}>
                  <Grid item md={5}>
                    <CssTextField
                      focusColor={"#c7c6c5"}
                      value={userDetails && userDetails.firstName}
                      name="firstName"
                      label="Ime"
                      type="text"
                      autoComplete="off"
                      {...register("firstName")}
                      fullWidth
                      variant="outlined"
                      my={2}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item md={5}>
                    <CssTextField
                      focusColor={"#c7c6c5"}
                      value={userDetails && userDetails.lastName}
                      name="lastName"
                      label="Priimek"
                      type="text"
                      autoComplete="off"
                      {...register("lastName")}
                      fullWidth
                      variant="outlined"
                      my={2}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item md={10}>
                    <CssTextField
                      focusColor={"#c7c6c5"}
                      value={userDetails && userDetails.department}
                      name="department"
                      label="Oddelek"
                      type="text"
                      autoComplete="off"
                      {...register("department")}
                      fullWidth
                      variant="outlined"
                      my={2}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item md={10}>
                    <CssTextField
                      focusColor={"#c7c6c5"}
                      value={userDetails && userDetails.email}
                      name="email"
                      label="Email"
                      type="email"
                      autoComplete="off"
                      {...register("email")}
                      fullWidth
                      variant="outlined"
                      my={2}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item md={5}>
                    <CssTextField
                      focusColor="black"
                      defaultValue={userDetails && userDetails.fixNumber}
                      name="fixNumber"
                      label="Telefonska številka"
                      type="tel"
                      autoComplete="off"
                      {...register("fixNumber")}
                      error={errors.fixNumber}
                      helperText={errors.username && errors.fixNumber.message}
                      fullWidth
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                  <Grid item md={5}>
                    <CssTextField
                      focusColor="black"
                      defaultValue={userDetails && userDetails.fixNumberShort}
                      name="fixNumberShort"
                      label="Kratka telefonska številka"
                      type="number"
                      autoComplete="off"
                      {...register("fixNumberShort")}
                      fullWidth
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                  <Grid item md={5}>
                    <CssTextField
                      focusColor={"#c7c6c5"}
                      value={userDetails && userDetails.mobNumber}
                      name="mobNumber"
                      label="Mobilna številka"
                      type="tel"
                      autoComplete="off"
                      {...register("mobNumber")}
                      fullWidth
                      variant="outlined"
                      my={2}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item md={5}>
                    <CssTextField
                      focusColor={"#c7c6c5"}
                      value={userDetails && userDetails.mobNumberShort}
                      name="mobNumberShort"
                      label="Kratka mobilna številka"
                      type="number"
                      autoComplete="off"
                      {...register("mobNumberShort")}
                      fullWidth
                      variant="outlined"
                      my={2}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={8}>
                  <Grid item md={5}>
                    <CssTextField
                      focusColor="black"
                      defaultValue={userDetails && userDetails.firstName}
                      name="firstName"
                      label="Ime"
                      type="text"
                      autoComplete="off"
                      {...register("firstName")}
                      fullWidth
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                  <Grid item md={5}>
                    <CssTextField
                      focusColor="black"
                      defaultValue={userDetails && userDetails.lastName}
                      name="lastName"
                      label="Priimek"
                      type="text"
                      autoComplete="off"
                      {...register("lastName")}
                      fullWidth
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                  <Grid item md={10}>
                    <CssTextField
                      focusColor="black"
                      defaultValue={userDetails && userDetails.department}
                      name="department"
                      label="Oddelek"
                      type="text"
                      autoComplete="off"
                      {...register("department")}
                      fullWidth
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                  <Grid item md={10}>
                    <CssTextField
                      focusColor="black"
                      defaultValue={userDetails && userDetails.email}
                      name="email"
                      label="Email"
                      type="text"
                      autoComplete="off"
                      {...register("email")}
                      error={errors.email}
                      helperText={errors.email && errors.email.message}
                      fullWidth
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                  <Grid item md={5}>
                    <CssTextField
                      focusColor={"#c7c6c5"}
                      value={userDetails && userDetails.fixNumber}
                      name="fixNumber"
                      label="Telefonska številka"
                      type="tel"
                      autoComplete="off"
                      {...register("fixNumber")}
                      error={errors.fixNumber}
                      helperText={errors.username && errors.fixNumber.message}
                      fullWidth
                      variant="outlined"
                      my={2}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item md={5}>
                    <CssTextField
                      focusColor={"#c7c6c5"}
                      value={userDetails && userDetails.fixNumberShort}
                      name="fixNumberShort"
                      label="Kratka telefonska številka"
                      type="number"
                      autoComplete="off"
                      {...register("fixNumberShort")}
                      fullWidth
                      variant="outlined"
                      my={2}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item md={5}>
                    <CssTextField
                      focusColor="black"
                      defaultValue={userDetails && userDetails.mobNumber}
                      name="mobNumber"
                      label="Mobilna številka"
                      type="tel"
                      autoComplete="off"
                      {...register("mobNumber")}
                      fullWidth
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                  <Grid item md={5}>
                    <CssTextField
                      focusColor="black"
                      defaultValue={userDetails && userDetails.mobNumberShort}
                      name="mobNumberShort"
                      label="Kratka mobilna številka"
                      type="number"
                      {...register("mobNumberShort")}
                      fullWidth
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                </Grid>
              )}

              <div style={{ marginTop: "20px" }}>
                <Button
                  className="btnA1"
                  type="submit"
                  variant="contained"
                  mt={3}
                >
                  Shrani
                </Button>
                &nbsp; &nbsp;
                <Button
                  onClick={() => navigate(-1)}
                  style={{ backgroundColor: "black" }}
                  type="button"
                  variant="contained"
                  mt={3}
                >
                  Prekliči
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
      )}

      <BootstrapDialog
        PaperProps={{ sx: { width: "30%", height: "40%" } }}
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
      >
        <DialogTitle>
          {isSuccessfull == true ? (
            <strong>Uspešno</strong>
          ) : (
            <strong>Napaka</strong>
          )}
        </DialogTitle>
        <DialogContent
          dividers
          style={{ textAlign: "center", padding: "20px" }}
        >
          {isSuccessfull == true ? (
            <>
              <CheckCircleOutline
                style={{ color: "green", fontSize: "80px" }}
              />
              <br />
              <br />
              <br />
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "17px",
                }}
              >
                Uspešno ste uredili uporabnika!
              </span>
            </>
          ) : (
            <>
              <ErrorOutline style={{ color: "red", fontSize: "80px" }} />
              <br />
              <br />
              <br />
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "17px",
                }}
              >
                {errorMessage && errorMessage}
              </span>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            className="btnA1"
            variant="contained"
            autoFocus
            onClick={handleCloseDialog}
          >
            V redu
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}

function UpdateUser() {
  return (
    <React.Fragment>
      <Helmet title="User" />
      <Typography variant="h3" gutterBottom display="inline">
        Uredi uporabnika
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/dashboard">
          Domov
        </Link>
        <Link component={NavLink} to="/users">
          Uporabniki
        </Link>
        <Typography>Uredi uporabnika</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EmptyCard />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default UpdateUser;
