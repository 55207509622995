import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Alert as MuiAlert,
  Checkbox,
  FormControlLabel,
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import {
  loginUser,
  useAuthState,
  useAuthDispatch,
} from "../../components/Context";
import Loading from "react-fullscreen-loading";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function SignIn() {
  const navigate = useNavigate();
  const dispatch = useAuthDispatch();
  const { loading } = useAuthState();

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const CssTextField = styled(TextField, {
    shouldForwardProp: (props) => props !== "focusColor",
  })((p) => ({
    // input label when focused
    "& label.Mui-focused": {
      color: p.focusColor,
    },
    // focused color for input with variant='standard'
    "& .MuiInput-underline:after": {
      borderBottomColor: p.focusColor,
    },
    // focused color for input with variant='filled'
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: p.focusColor,
    },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: p.focusColor,
      },
    },
  }));

  return (
    <>
      <Formik
        initialValues={{
          userName: "",
          password: "",
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          userName: Yup.string()
            .max(255)
            .required("Vnešeno ni bilo nobeno uporabniško ime."),
          password: Yup.string()
            .max(255)
            .required("Vnešeno ni bilo nobeno geslo."),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          setIsLoading(true);
          let password = values.password;
          let username = values.userName;
          try {
            let response = await loginUser(dispatch, { password, username });

            if (response.status !== 401) {
              setErrorMessage("");
              localStorage.setItem("userTokenSlo", response.token);
              localStorage.setItem("currentUserSlo", response);
              localStorage.setItem(
                "currentUserSloCompanyId",
                response.companyId
              );
              localStorage.setItem(
                "nameOfUserSlo",
                response.firstName + " " + response.lastName
              );
              localStorage.setItem("roleOfUserSlo", response.role);
              localStorage.setItem("currentUserSloUserId", response.userId);
              setIsLoading(false);
              navigate("/dashboard");
            } else {
              localStorage.removeItem("currentUserSloCompanyId");
              localStorage.removeItem("userTokenSlo");
              localStorage.removeItem("currentUserSlo");
              localStorage.removeItem("nameOfUserSlo");
              localStorage.removeItem("roleOfUserSlo");
              setErrorMessage("Please enter valid login information.");
              setIsLoading(false);
              navigate("/");
            }
          } catch (error) {
            localStorage.removeItem("currentUserSloUserId");
            localStorage.removeItem("currentUserSloCompanyId");
            localStorage.removeItem("userTokenSlo");
            localStorage.removeItem("currentUserSlo");
            localStorage.removeItem("nameOfUserSlo");
            localStorage.removeItem("roleOfUserSlo");
            setErrorMessage("Vnešeno uporabniško ime ali geslo ni veljavno.");
            setIsLoading(false);
            navigate("/");
            console.log(error);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errorMessage && (
              <Alert mt={2} mb={3} severity="warning">
                {errorMessage}
              </Alert>
            )}
            <CssTextField
              type="text"
              name="userName"
              autoComplete="off"
              label="Uporabniško ime"
              value={values.userName}
              error={Boolean(touched.userName && errors.userName)}
              fullWidth
              helperText={touched.userName && errors.userName}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
              focusColor="black"
            />
            <CssTextField
              type="password"
              name="password"
              label="Geslo"
              value={values.password}
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              onBlur={handleBlur}
              onChange={handleChange}
              focusColor="black"
              my={2}
            />
            {/* <FormControlLabel
            control={
              <Checkbox
                value="remember"
                style={{
                  color: "#0c0c0d",
                }}
              />
            }
            label="Zapamti me"
          /> */}
            <Button
              style={{ backgroundColor: "black", color: "white" }}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={
                values.email == "" || values.password == "" ? true : false
              }
            >
              Prijava
            </Button>
          </form>
        )}
      </Formik>
      <Loading
        loading={isLoading}
        background="rgba(60, 60, 60, 0.5)"
        loaderColor="#d71920"
      />
    </>
  );
}

export default SignIn;
