import * as React from "react";
import styled, { withTheme } from "styled-components/macro";
import { darken } from "polished";
import { Search as SearchIcon } from "react-feather";
import "./search.css";

import {
  Grid,
  Hidden,
  InputBase,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  TableCell,
  Button,
  Link,
} from "@mui/material";

import { Close, Menu as MenuIcon, ArrowForwardIos } from "@mui/icons-material";
import NavbarUserDropdown from "./NavbarUserDropdown";
import { useNavigate } from "react-router-dom";
import * as dateHelper from "../Config/DateHelper";
import { tableCellClasses } from "@mui/material/TableCell";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: #233044;
    color: ${(props) => props.theme.palette.common.white};
    border-right: 1px solid black;
    padding: 5px;
    margin-top: 10px;
    height: 10px;
  }
  &.${tableCellClasses.body} {
    font-size: 14px;
    padding: 5px;
    border-right: 1px solid black;
    height: 13px;
  }
`;

const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 160px;
  }
`;

const Navbar = ({ onDrawerToggle }) => {
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = React.useState([]);
  const [wordEntered, setWordEntered] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const [isVisibleResults, setIsVisibleResults] = React.useState(false);
  const [detailsDirection, setDetailsDirection] = React.useState("");
  const [search, setSearch] = React.useState({
    caseId: "",
    guid: "",
    adapterId: "",
    dateFrom: null,
    dateTo: null,
    operator: 0,
    type: 0,
    category: 0,
    status: 0,
    statusInt: 0,
  });

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                  size="large"
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xs></Grid>
            <Grid item>
              <a
                href="https://uc.a1.si"
                target="_blank"
                rel="noreferrer"
                style={{
                  textDecoration: "none",
                  color: "red",
                  fontSize: "h4",
                }}
              >
                CommPortal
              </a>
            </Grid>
            <Grid item>
              <NavbarUserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(Navbar);
