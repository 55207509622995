import React from "react";

import async from "./components/Async";
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import AuthGuard from "./components/guards/AuthGuard";
import SignIn from "./pages/auth/SignIn";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";
import EditUser from "./pages/tables/Users/EditUser";
import UserDetail from "./pages/tables/Users/UserDetails";

const Default = async(() => import("./pages/dashboards/Default"));
const DataGridCompanies = async(() =>
  import("./pages/tables/Companies/DataGridCompanies")
);

const DataGridUsers = async(() => import("./pages/tables/Users/DataGridUsers"));

const DataGridCorporate = async(() =>
  import("./pages/tables/Phonebook/Corporate/DataGridCorporate")
);
const AddContactCorporate = async(() =>
  import("./pages/tables/Phonebook/Corporate/AddContactCorporate")
);
const ImportContactCorporate = async(() =>
  import("./pages/tables/Phonebook/Corporate/ImportContactCorporate")
);
const EditContactCorporate = async(() =>
  import("./pages/tables/Phonebook/Corporate/EditContactCorporate")
);
const ContactDetailsCorporate = async(() =>
  import("./pages/tables/Phonebook/Corporate/ContactDetailsCorporate")
);

const DataGridUsersPersonal = async(() =>
  import("./pages/tables/Phonebook/Personal/DataGridPersonal")
);
const AddContactPersonal = async(() =>
  import("./pages/tables/Phonebook/Personal/AddContactPersonal")
);
const EditContactPersonal = async(() =>
  import("./pages/tables/Phonebook/Personal/EditContactPersonal")
);
const ContactDetailsPersonal = async(() =>
  import("./pages/tables/Phonebook/Personal/ContactDetailsPersonal")
);
const ImportContactPersonal = async(() =>
  import("./pages/tables/Phonebook/Personal/ImportContactPersonal")
);

const routes = [
  {
    path: "/dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Default />,
      },
    ],
  },

  {
    path: "/",
    element: <AuthLayout />,
    children: [
      {
        path: "/",
        element: <SignIn />,
      },
    ],
  },

  {
    path: "/companies",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <DataGridCompanies />,
      },
    ],
  },

  {
    path: "/users",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <DataGridUsers />,
      },
      {
        path: "edit-user/:id",
        element: <EditUser />,
      },
      {
        path: "user-detail/:id",
        element: <UserDetail />,
      },
    ],
  },

  {
    path: "/phonebook",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "corporate",
        element: <DataGridCorporate />,
      },
      {
        path: "corporate/edit-contact/:id",
        element: <EditContactCorporate />,
      },
      {
        path: "corporate/contact-details/:id",
        element: <ContactDetailsCorporate />,
      },
      {
        path: "corporate/new-contact",
        element: <AddContactCorporate />,
      },
      {
        path: "corporate/new-contact-import",
        element: <ImportContactCorporate />,
      },
      {
        path: "personal",
        element: <DataGridUsersPersonal />,
      },
      {
        path: "personal/edit-contact/:id",
        element: <EditContactPersonal />,
      },
      {
        path: "personal/new-contact",
        element: <AddContactPersonal />,
      },
      {
        path: "personal/contact-details/:id",
        element: <ContactDetailsPersonal />,
      },
      {
        path: "personal/new-contact-import",
        element: <ImportContactPersonal />,
      },
    ],
  },

  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
